import React, { useMemo } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { Badge } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./styles.module.css";
import dumyAavtar from '../../styles/dumyavatar.jpeg'

export default function Default({
  title,
  name,
  designation,
  username,
  user_image,
  className,
  children,
  unassigned = false,
}) {

  const convertedImage = user_image ? user_image : name;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const avatar = useMemo(()=> <StyledBadge
  overlap="circular"
  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  variant="dot">
  <Avatar
    alt={username}
    src={convertedImage}
    title=""
    className={styles.avatar}>
    {user_image ? null : name?.split(' ').map(ele=>ele[0]).join('')}
  </Avatar>
</StyledBadge>,[convertedImage])


  return (
    <Box>
      <Stack className={styles.container}>
        {avatar}
        <Box>

          <Typography className={styles.nameTypography}>{name}</Typography>

          <Typography className={styles.designationTypography}>{designation}</Typography>
          
        </Box>

          <KeyboardArrowDownIcon sx={{ color: "#777777" }} />
      </Stack>
    </Box>
  );
}
