import { useQuery } from "@tanstack/react-query";
import getShortDays from "../../services/count/apiShortDays";

export function ShortDaysQuery(){
    const {data, isLoading, isError} = useQuery({
        queryKey: ['shortDays'],
        queryFn: ()=> getShortDays()
    })

    const shortDays = data?.data.total_short_days;

    return {shortDays,data, isLoading, isError}
}