import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import TableHeader from '../../../components/TableHeader/TableHeader'
import styles from "./styles.module.css";
import DataGridComp from '../../../components/DataGrid/DataGrid';
import viewIcon from '../../../styles/icons/viewicon.svg';
import downloadIcon from '../../../styles/icons/downloadIcon.svg';
import { PolicyListQuery } from '../../../utils/reactQuery/policy/PolicyList';
import NotesPopup from '../../../components/NotesPopup/NotesPopup';
import { LoadingButton } from '@mui/lab';
import getDownloadPolicy from '../../../services/policy/apiDownloadPolicy';
import { useResponsive } from '../../../hooks/customUseResponsive';
import { useMutation } from '@tanstack/react-query';
import { usDateFormate } from '../../../hooks/dateFormat';
import { postReadPolicy } from '../../../services/policy/apiReadPolicy';

export default function Policies() {
  const {policyList,refetch} = PolicyListQuery();
  const [notePopup, setNotePopup] = useState(false);
  const [noticeId, setNoticeId] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(null);
  const isMobile = useResponsive('down', 'sm');

  const { mutate } = useMutation({
    mutationFn: postReadPolicy,
    onSuccess: data => {
      refetch();
 },
   onError: () => {
        alert("there was an error")
 }
 });

  const handleDownload=(id)=>{
    setDownloadLoading(id);

    getDownloadPolicy(id).then((res)=>{

      const fileUrl = res.data.url; // Adjust this based on the structure of your response

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', ''); // Optional: specify a filename
        document.body.appendChild(link);
        link.click();
        link.remove();

      setDownloadLoading(null);
    }).catch((err)=>{
      setDownloadLoading(false);
      console.log(err)
    })
  }

  const handleView = (id)=>{
    setNoticeId(id);
    setNotePopup(true);
    mutate({id});
  }

  const columns = [
    {
      field: "col1",
      headerName: "Subject",
      minWidth: isMobile ? 130 :250,
      flex: 2,
      sortable: false,
      resizable: false,
      renderCell: (value)=>{
        return <Stack direction='row' className={styles.noticeSubject}>
          <Typography className={styles.title}>{value.formattedValue.name}</Typography>
          {value.formattedValue.isRead ? '' : <Box className={styles.greenBadge}></Box>}
        </Stack>
      }
    },
    {
      field: "col2",
      headerName: "Create Date",
      minWidth: isMobile ? 100 :140,
      flex: 1.1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{usDateFormate(value.value)}</span>
    },
    {
      field: "col3",
      headerName: "Actions",
      minWidth: isMobile ? 100 :123,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack className={styles.btnCont}>
            <Box className={styles.viewBtn} onClick={()=>handleView(value.value)}>
              <img className={styles.icon} src={viewIcon} alt='view'/>
            </Box>
            <Box className={styles.downloadBtnCont}>
              <LoadingButton className={styles.downloadBtn} onClick={()=>handleDownload(value.value)} loading={downloadLoading === value.value}>
              <img className={downloadLoading === value.value ? styles.loadIcon :styles.icon} src={downloadIcon} alt='download'/>
              </LoadingButton>
            </Box>
          </Stack>
        );
      },
    }
  ];

  let initialRows = policyList ? policyList.map((ele, index)=>{
    return {
      id: index,
      col1: {name: ele.policy_name, isRead: ele.is_read_by_employee},
      col2: ele.date_added,
      col3: ele.policy_id
    }
  }) : [];

  return (
    <Box className={styles.mainContainer}>
      <TableHeader heading={"Policies"}/>
      <Box className={styles.datagridCont}>
        <DataGridComp
        columns={columns}
        initialRows={initialRows?initialRows: []}
      />
        </Box>
        {notePopup ? <NotesPopup openPop={notePopup} handleClose={()=>setNotePopup(false)} id={noticeId} policy={true}/>: ''}
    </Box>
  )
}
