import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Logo from "../../styles/svg/sofmenLogo.svg";
import { localStorageInstance } from "../../utils/localStorage";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { postLogin } from "../../services/auth/apiLogin";
import { LoadingButton } from "@mui/lab";
import styles from "./styles.module.css";
import PersonIcon from "@mui/icons-material/Person";
import lockIcon from "../../styles/svg/lockIcon.svg";
import { useResponsive } from "../../hooks/customUseResponsive";

function Login() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {},
  });
  const isMobile = useResponsive("down", "sm");
  const { errors } = formState;

  const { mutate, isPending } = useMutation({
    mutationFn: postLogin,
    onSuccess: (data) => {
      localStorageInstance.setItem({ key: "token", value: data.data.token });
      localStorageInstance.setItem({ key: "role", value: data.data.role });
      if (data.data.token) {
        navigate("/dashboard");
      } else {
        toast.error(data.message);
      }
    },
    onError: (data) => {
      console.log(data.response.data);
      if (data.response.data.message === "Unauthorized") {
        toast.error("Invalid credentials");
      }
    },
  });

  const submit = (data) => {
    /**
     * Login User
     */
    mutate({ passWord: data.password, userName: data.username });
  };

  function onError(errors) {
    console.log(errors);
  }
  return (
    <Stack
      component="main"
      className={styles.mainContainer}>
      <Box className={styles.leftSideSection}>
        <Stack className={styles.logoCont}>
          <img src={Logo} alt="not found" className={styles.logo} />
          <Typography className={styles.txtBelowLogo}>
            Sofmen Applicant Management System
          </Typography>
        </Stack>
      </Box>
      <Box
        className={styles.rightSideSection}
        component="form"
        onSubmit={handleSubmit(submit, onError)}>
        <Stack className={styles.formCont}>
          <Stack className={styles.mobileLogoCont}>
            <img src={Logo} alt="not found" className={styles.mobileLogo} />
          </Stack>
          <Typography className={styles.loginTxt}>Login</Typography>
          <Typography className={styles.loginContent}>
            Please login with your LDAP Username and Password.
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="username"
            placeholder="Username"
            error={errors?.email ? true : false}
            helperText={errors?.email && errors.email.message}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={styles.inputAdornment}
                  position="start">
                  <PersonIcon className={styles.icon} />
                </InputAdornment>
              ),
            }}
            autoComplete="email"
            autoFocus
            {...register("username", {
              required: "Username is required",
            })}
            sx={{
              "& .MuiInputBase-input": {
                padding: isMobile ? "8px !important" : "",
                paddingLeft: isMobile ? "2px !important" : "10px !important",
              },
            }}
            className={styles.textField}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            error={errors?.password ? true : false}
            helperText={errors?.password && errors.password.message}
            autoComplete="current-password"
            {...register("password", {
              required: "Password is required",
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={styles.inputAdornment}
                  position="start">
                  <img src={lockIcon} alt="lock" className={styles.lockIcon} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: isMobile ? "8px !important" : "",
                paddingLeft: isMobile ? "2px !important" : "10px !important",
              },
            }}
            className={styles.textField}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className={styles.loginBtn}
            sx={{ mt: "14px", padding: "13px 16px" }}
            loading={isPending}>
            Login
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Login;
