// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1B3E61', // Your primary color
    },
    secondary: {
      main: '#0E194F', // Your secondary color
    },
    danger: {
        main: '#FF0000'
    }
    // You can define other colors like error, warning, info, success, etc.
  },
});

export default theme;