import { useQuery } from "@tanstack/react-query"
import { getMissingEntryList } from "../../../services/manager/count/apiMissingEntryList"
import { usDateFormate } from "../../../hooks/dateFormat"
import toast from "react-hot-toast"
import { useMemo } from "react"

export const MissingEntriesQuery=({startDate, endDate})=>{
    const {data, isLoading} = useQuery({
        queryKey: ['missingEntries',startDate,endDate],
        queryFn: ()=>getMissingEntryList({startDate: startDate, endDate: endDate})
    })
    let missingEntries = []
    
    if(data?.data.message){
        // toast.error(data.data.message)
    }else{
    missingEntries = data?.data.map((ele,idx)=>{
        return{
            id: idx,
            col1: ele.employee_name,
            col2: usDateFormate(ele.date? ele.date : ''),
            col3: {id: ele.employee_id, date: ele.date}
        }
    })}

    return {missingEntries, isLoading}
}