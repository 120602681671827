import { useQuery } from "@tanstack/react-query"
import { getShortDayCount } from "../../../../services/manager/count/apiShortDayCount"

export const ShortDaysCount =()=>{
    const {data, isLoading: shortHourLoading} = useQuery({
        queryKey: ['shortDaysCount'],
        queryFn: ()=>getShortDayCount()
    })
    const shortDayCount = data?.data.total_employees_short_day_count;
    
    return {shortDayCount, shortHourLoading}
}