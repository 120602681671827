import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import App from "./App";

import ErrorFallback from "./components/ErrorFallback";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => window.location.replace("/")}
  >
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
  </ErrorBoundary>
);
