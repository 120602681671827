import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DataGridComp from "../DataGrid/DataGrid";
import { ManualListQuery } from "../../utils/reactQuery/attendance/ManualList";
import { useResponsive } from "../../hooks/customUseResponsive";
import { localStorageInstance } from "../../utils/localStorage";
import TotalHourQuery from "../../utils/reactQuery/attendance/TotalHours";
import { InOutMissingEntry } from "../../utils/reactQuery/manager/count/InoutMissingEntry";
import { TotalHoursMissingEntry } from "../../utils/reactQuery/manager/count/TotalHoursMissingEntry";
import { DataGrid } from "@mui/x-data-grid";
import { usDateFormate } from "../../hooks/dateFormat";
import viewIcon from '../../styles/icons/viewicon.svg'
import ManagerNote from "../managerNote/ManagerNote";
import NotesPopup from "../NotesPopup/NotesPopup";

export default function InOutModal({
  openProp,
  handleClose,
  date,
  day,
  empId,
}) {
  const isMobile = useResponsive("down", "sm");
  const [selectedId, setSelectedId] = useState();
  const [reasonModal, setReasonModal] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 592,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    // maxHeight: '90vh',
    // overflowX: 'auto'
  };

  const userDetails = localStorageInstance.getItem("user");
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (empId) {
    var { list, isLoading } = InOutMissingEntry({ id: empId, date: date });
    var { totalHours } = TotalHoursMissingEntry({ id: empId, date: date });
  } else {
    var { list: listManual, isLoading: manualIsLoading } = ManualListQuery({
      date,
    });
    var { totalHours: manualTotalHours } = TotalHourQuery({ date });
  }
  console.log("list == ", list,listManual)

  const columns = [
    {
      field: "col1",
      headerName: "In Time",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Out Time",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Total Time",
      minWidth: 120,
      sortable: false,
      flex: 1,
      // align: "center",
      // headerAlign: "center",
      // type: "number",
      renderCell: (value) => <Stack className={styles.totalTimeCont}>
        <Typography className={styles.totalTime}>{value.value.time}</Typography>
        {value.value.isManual? <img className={styles.icon} src={viewIcon} alt="view icon" onClick={()=>handleView(value.value.appId)}/> : ''}
      </Stack>,
    },
  ];

  const handleView =(id)=>{
    setSelectedId(id);
    setReasonModal(true);
  }

  var initialRows = [];
  if (empId) {
    initialRows = list
      ? list.map((ele, idx) => {
          return {
            id: idx,
            col1: ele.check_in_time,
            col2: ele.check_out_time,
            col3: {time: ele.total_time,isManual: ele.is_manual, appId: ele.attendance_id},
          };
        })
      : [];
  } else {
    initialRows = listManual
      ? listManual.map((ele, idx) => {
          return {
            id: idx,
            col1: ele.check_in_time,
            col2: ele.check_out_time,
            col3: {time: ele.total_time,isManual: ele.is_manual, appId: ele.attendance_id},
          };
        })
      : [];
  }

  console.log("manual list ----", initialRows);

  return (
    <Modal
      open={openProp}
      onClose={handleClose}
      sx={{ padding: "20px !important" }}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>In / Out Time</Typography>
          <Box onClick={() => handleClose()}>
            <CloseIcon className={styles.icon} />
          </Box>
        </Stack>
        {isLoading || manualIsLoading ? (
          <Stack className={styles.progressBar}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box>
            <Box className={styles.bodyCont}>
              {empId ? (
                <Typography className={styles.bodyHead}>
                  {totalHours.employee_name} | {totalHours.day},{" "}
                  {monthNames[parseInt(date.split("-")[1])]}{" "}
                  {date.split("-")[2]}, {date.split("-")[0]} | Total Time :{" "}
                  {totalHours.total_hours_spent}
                </Typography>
              ) : (
                <Typography className={styles.bodyHead}>
                  {userDetails.name} | {weekDays[day]},{" "}
                  {monthNames[parseInt(date.split("-")[1])]}{" "}
                  {date.split("-")[2]}, {date.split("-")[0]} | Total Time :{" "}
                  {manualTotalHours}
                </Typography>
              )}
            </Box>
            <Box
              className={styles.datagridCont}
              sx={{ height: initialRows.length > 7 ? "500px !important" : "" }}>
              {/* <DataGridComp columns={columns} initialRows={initialRows} /> */}
              <DataGrid
                columns={columns}
                rows={initialRows}
                disableColumnMenu
                getRowClassName={(params) =>
                  params.row.disabled
                    ? "MuiDataGrid-row disabled"
                    : "MuiDataGrid-row disabled"
                }
                initialState={{
                  pagination: { paginationModel: { pageSize: 99 } },
                }}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                autoHeight={initialRows.length > 7 ? false : true}
                hideFooter={true}
                sx={{
                  border: 0,
                  "& .MuiDataGrid-main": {
                    border: "1px solid #CED4DA",
                    borderRadius: "4px",
                    // pointerEvents: 'none',
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "14px !important",
                    fontWeight: "400 !important",
                    color: "#455F69",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    height: "48px !important",
                    backgroundColor: "#F5F8FC",
                    color: "#455F69",
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    borderBottom: "1px solid #CED4DA",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    border: "none", // Ensure the footer has no border
                  },
                  "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
              />
            </Box>
          </Box>
        )}
        {reasonModal ? <NotesPopup openPop={reasonModal} handleClose={()=>setReasonModal(false)}  id={selectedId} manual={true}/>: ''}
      </Box>
    </Modal>
  );
}
