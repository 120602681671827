import { Box, Divider, Modal, Stack, Typography, CircularProgress} from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { ViewFeedbackQuery } from "../../../../utils/reactQuery/manager/EmployeeFeedback/ViewFeedback";
import ReactQuill from "react-quill";

export default function ViewFeedback({ openProp, handleClose, id }) {
  const isMobile = useResponsive("down", "sm");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1097,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    scrollY: "scroll",
    maxHeight: '90vh',
    overflowX: 'auto'
  };

  const { viewFeedback, isLoading} = ViewFeedbackQuery(id);

  // console.log("view feedback data", viewFeedback);

  const modules = {
    toolbar: false // Disable the toolbar completely
  };
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <Box className={styles.container}>
          <Box className={styles.greenTab} />
          <Stack direction="row" className={styles.headerContainer}>
            <Typography className={styles.tableHead}>
              {viewFeedback?.employee_name}
            </Typography>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
        </Box>

        {isLoading ? <Stack className={styles.loaderCont}>
          <CircularProgress/> 
          </Stack>: 

        <Stack className={styles.bodyContainer}>
          
          <Stack className={styles.subCont}>
            <Typography className={styles.label}>Employee Name: </Typography>
            <Typography className={styles.content}>
              {viewFeedback?.employee_name}
            </Typography>
          </Stack>

          <Stack className={styles.subCont}>
            <Typography className={styles.label}>Review Period: </Typography>
            <Typography className={styles.content}>
              {viewFeedback?.quarter}
            </Typography>
          </Stack>

          <Divider />

          <Stack className={styles.textEditorCont}>
            <ReactQuill
            value={viewFeedback?.description}
            readOnly={true}
            modules={modules}
            style={{height: '200px', width: '100%'}}/>
            {/* <div
              dangerouslySetInnerHTML={{ __html: viewFeedback?.description }}
              className={styles.noteDescription}></div> */}
          </Stack>
        </Stack>}
      </Box>
    </Modal>
  );
}
