import { Box, Modal, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import { useResponsive } from "../../hooks/customUseResponsive";
import { localStorageInstance } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export default function ConfirmModal({
  openProp,
  handleClose,
  btnText,
  tabHeader,
  heading,
  content,
  handleConfirm,
}) {
  const isMobile = useResponsive("down", "sm");
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "300px" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
  };
  
  // useEffect(()=>{

  //   return ()=>{
  //     setLoading(false);
  //   }
  // },[])

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>{tabHeader}</Typography>
          <Box onClick={() => handleClose()}>
            <CloseIcon className={styles.icon} />
          </Box>
        </Stack>

        <Stack className={styles.modalBody}>
          {heading ? (
            <Typography className={styles.subHead}>{heading}</Typography>
          ) : (
            ""
          )}
          <Typography className={styles.text}>{content}</Typography>
          <Stack className={styles.btnCont}>
            <Button
              variant="outlined"
              className={styles.cancelBtn}
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              className={styles.logoutBtn}
              onClick={() => {
                setLoading(true);
                handleConfirm();
              }}>
              {btnText}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
