import { useQuery } from '@tanstack/react-query'
import { getEmployeeDetails } from '../../../../services/manager/employeeDetails/apiEmployeeDetails'
import { usDateFormate } from '../../../../hooks/dateFormat'


export default function EmployeeDetailQuery() {
    const {data, isLoading} = useQuery({
        queryKey: ['employee_detail_list'],
        queryFn: ()=>getEmployeeDetails()
    })

    const employeeList = data?.data.employees.map((ele,idx)=>{
        return{
            id: idx,
        col1: ele.name,
        col2: usDateFormate(ele.date_added?ele.date_added:''),
        col3: ele.technology,
        col4: ele.mobile_number,
        col5: ele.employee_id
        }
    })

    return {employeeList,isLoading}
}
