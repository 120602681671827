import React from 'react'
import { useQuery } from '@tanstack/react-query'
import {getManagerProfileInfo} from '../../../../services/manager/managerProfile/apiPersonalInfo'

export default function ManagerProfileInfoQuery(id) {
  const {data, isLoading} = useQuery({
    queryKey: ['managerPErsonalInfo', id],
    queryFn: ()=> getManagerProfileInfo({id})
  })

const employee = data?.data.employee
  return {employee, isLoading}
}
