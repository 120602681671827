import { useQuery } from "@tanstack/react-query";
import getPresentMonthLeave from "../../services/count/apiPresentMonthLeave";

export function PresentMonthLeaves(){
    const {data, isLoading, isError} = useQuery({
        queryKey: ['presentMonthLeaves'],
        queryFn: ()=> getPresentMonthLeave()
    })

    const presentMonthLeaves = data?.data.total_leave;

    return {presentMonthLeaves, isLoading, isError}
}