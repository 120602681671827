import { localStorageInstance } from "../../utils/localStorage";
import Api from "../axios";

export const postLeaveApp = async ({
  startDate,
  endDate,
  message,
  startTime,
  endTime,
  applicationType,
}) => {
  let empId = localStorageInstance.getItem("user").employee_id;

  return await Api.post("/leave_application", {
    start_date: `${new Date(startDate).getFullYear()}-${
      new Date(startDate).getMonth() + 1 < 10
        ? "0" + (new Date(startDate).getMonth() + 1)
        : new Date(startDate).getMonth() + 1
    }-${
      new Date(startDate).getDate() < 10
        ? "0" + (new Date(startDate).getDate())
        : new Date(startDate).getDate()
    }`,
    end_date: `${new Date(endDate).getFullYear()}-${
      new Date(endDate).getMonth() + 1 < 10
        ? "0" + (new Date(endDate).getMonth() + 1)
        : new Date(endDate).getMonth() + 1
    }-${
      new Date(endDate).getDate() < 10
        ? "0" + (new Date(endDate).getDate())
        : new Date(endDate).getDate()
    }`,
    application_type: applicationType,
    start_time: startTime,
    end_time: endTime,
    employee_id: empId,
    reason: message,
  });
};
