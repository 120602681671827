import { useQuery } from "@tanstack/react-query"
import { getMissingStatusCount } from "../../../../services/manager/count/apiMissingStatusCount"

export const MissingStatusCount=()=>{
    const {data, isLoading: missingStatusLoading} = useQuery({
        queryKey: ['missingStatusCount'],
        queryFn: ()=>getMissingStatusCount()
    })

    const missingStatusCount = data?.data.missing_report_count

    return {missingStatusCount, missingStatusLoading}
}