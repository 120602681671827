import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import UpcomingLeaveQuery from "../../../utils/reactQuery/manager/UpcomingLeaves";

export default function UpcomingLeaves() {
  const {upcomingLeaves, isLoading} = UpcomingLeaveQuery();

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 145,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col2",
      headerName: "Start Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col3",
      headerName: "End Date",
      minWidth: 110,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col4",
      headerName: "Total Days",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col5",
      headerName: "Applied On",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col6",
      headerName: "Reason",
      minWidth: 120,
      flex: 3,
      sortable: false,
      resizable: false,
      cellClassName: "last-col", 
    },
  ];
  return (
    <Box className={styles.mainContainer}>
      <span id="upcomingLeaves" className={styles.upcomingLeaves}></span>
      <Typography className={styles.heading}>Upcoming Leaves</Typography>
      <Box className={styles.datagridCont}>
      {isLoading ? <Stack className={styles.loaderCont}><CircularProgress/></Stack> : ''}
        <DataGridComp columns={columns} initialRows={upcomingLeaves ? upcomingLeaves :[]} />
      </Box>
    </Box>
  );
}
