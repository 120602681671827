import { useQuery } from "@tanstack/react-query";
import getPolicyList from "../../../services/policy/apiPolicies";

export function PolicyListQuery(){
    const {data,refetch, isLoading, isError} = useQuery({
        queryKey: ['policyLists'],
        queryFn: ()=> getPolicyList()
    })

    const policyList = data?.data;

    return {policyList,refetch, isLoading, isError}
}