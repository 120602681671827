import { useQuery } from "@tanstack/react-query"
import { getViewFeedback } from "../../../../services/manager/EmployeeFeedback/apiViewFeedback"

export const ViewFeedbackQuery=(id)=>{
    const {data,isLoading} = useQuery({
        queryKey: ['viewFeedback',id],
        queryFn: ()=>getViewFeedback({id})
    })

    const viewFeedback = data?.data.feedback_data

    return {viewFeedback, isLoading}
}