import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DataGridComp from "../../../components/DataGrid/DataGrid";

import { useResponsive } from "../../../hooks/customUseResponsive";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { DatePicker } from "@mui/x-date-pickers";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { MissingEntriesQuery } from "../../../utils/reactQuery/manager/MissingEntries";
import { usDateFormate } from "../../../hooks/dateFormat";
import viewIcon from "../../../styles/icons/viewicon.svg";
import calenderIcon from "../../../styles/icons/calenderIcon.svg";
import InOutModal from "../../../components/InOutModal/InOutModal";

export default function OneWayEntries({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");
  const [dateRange, setDateRange] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  var d = new Date();
  const selectedEndDate = useRef(
    `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  );

  d.setDate(d.getDate() - 30);
  const selectedStartDate = useRef(
    `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  );
  const [inOutModal, setInOutModal]= useState(false);
  const selectedDate = useRef('');
  const selectedId = useRef('');

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const { missingEntries, isLoading} = MissingEntriesQuery({
    startDate: selectedStartDate.current,
    endDate: selectedEndDate.current,
  });

  const handleClick = (event) => {
    setDateRange(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    selectedStartDate.current = `${state[0].startDate.getFullYear()}-${state[0].startDate.getMonth()+1}-${state[0].startDate.getDate()}`;

    selectedEndDate.current = `${state[0].endDate.getFullYear()}-${state[0].endDate.getMonth()+1}-${state[0].endDate.getDate()}`;

    setDateRange(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const handleInOutModal=(id, date)=>{
    selectedDate.current = date;
    selectedId.current = id;
    setInOutModal(true);
  }

  const handleClearBtn=()=>{
    selectedEndDate.current = " ";
    selectedStartDate.current = " ";
    setDateRange(false)
  }

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 120,
      flex: 2.4,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value)=><span>{value.value}</span>
    },
    {
      field: "col3",
      headerName: "Action",
      minWidth: 120,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      renderCell: (value) => (
        <Stack className={styles.iconCont}>
          <img className={styles.icon} src={viewIcon} onClick={()=>handleInOutModal(value.value.id,value.value.date)}/>
        </Stack>
      ),
    },
  ];

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <Box className={styles.container}>
          <Box className={styles.greenTab} />
          <Stack direction="row" className={styles.headerContainer}>
            <Typography className={styles.tableHead}>
              Employees One Way Entries
            </Typography>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
        </Box>
        <Box className={styles.bodyCont}>
          <Stack className={styles.filterByDateCont}>
            <Typography className={styles.filterDate}>
              Filter by Date:
            </Typography>

            <TextField
              onClick={(event) => handleClick(event)}
              value={
                selectedStartDate.current!=' '
                  ? `${usDateFormate(
                      selectedStartDate.current
                    )}-${usDateFormate(selectedEndDate.current)}`
                  : ""
              }
              sx={{width: '293px', '& .MuiInputBase-input': {
                padding: '9px',  // Adjust padding as needed
                fontSize: '14px',
                fontWeight: '500',
                paddingLeft: '16px'
              },}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={calenderIcon} />
                  </InputAdornment>
                )
              }}
            />

            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={dateRange}
              onClose={() => setDateRange(false)}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
              <Stack className={styles.btnCont}>
                <Button onClick={handleSubmit} className={styles.submitBtn}>submit</Button>

                <Button className={styles.closeBtn} onClick={handleClearBtn} variant="outlined">clear</Button>

                <Button className={styles.closeBtn} onClick={() => setDateRange(false)} variant="outlined">close</Button>

              </Stack>
            </Menu>
          </Stack>
          <Box className={styles.datagridCont}>
          {isLoading ? <Stack className={styles.loaderCont}><CircularProgress/></Stack> : ''}
            <DataGridComp
              columns={columns}
              initialRows={missingEntries ? missingEntries : []}
              pageSize={7}
            />
          </Box>
        </Box>
        {inOutModal ? <InOutModal openProp={inOutModal} handleClose={()=>setInOutModal(false)} date={selectedDate.current} empId={selectedId.current}/> : ''}
      </Box>
    </Modal>
  );
}
