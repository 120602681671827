import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import { postLeaveApp } from "../../../../services/leave/apiAddLeaveApp.js";
import { addAdminLeaveSchema } from "../../../../schemas/addAdminLeaveSchema.js";
import AddAttendanceSchema from "../../../../schemas/addAttendanceSchema.js";
import RHFRadioGroup from "../../../../components/RFH/RFHradioGroup.js";
import RHFTimePicker from "../../../../components/RFH/RFHtimePicker.js";

export default function AddAttendanceModal({
  openProp,
  handleClose,
  listOfEmp,
}) {
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "sm");
  console.log("list of employee under manager", listOfEmp);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    type: "in",
  };
  const methods = useForm({
    resolver: yupResolver(AddAttendanceSchema),
    defaultValues,
  });

  const selectedType = methods.watch("type");

  //   const { mutate, isPending } = useMutation({
  //     mutationFn: postLeaveApp,
  //     onSuccess: (data) => {
  //       toast.success(data.data.message);
  //       methods.reset();
  //       queryClient.invalidateQueries({
  //         queryKey: ["leaveLogList"],
  //       });
  //       handleClose();
  //     },
  //     onError: (data) => {
  //       console.log("error --- ", data.response.data.error);
  //       toast.error(data.response.data.error);
  //     },
  //   });
  const options = [
    { value: "in", label: "In" },
    { value: "out", label: "Out" },
    { value: "in-out", label: "In-Out" },
  ];

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    // mutate({ ...formData });
  };

  console.log("errors === ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Employee Attendance</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>
                  Select Employee
                </Typography>
                <RHFSelect name="employeeId">
                  {listOfEmp.map((ele, idx) => {
                    return (
                      <MenuItem value={ele.employee_id} key={idx}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </RHFSelect>
              </Box>
              <Box>
                <Typography className={styles.label}>Date</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="startDate" />
                </Stack>
              </Box>

              <Box>
                <Stack ml={1} className={styles.inputFeild} direction="row">
                  <RHFRadioGroup options={options} name="type" row={true} />
                </Stack>
              </Box>
              <Stack direction="row" spacing={2}>
                {(selectedType === "in" || selectedType === "in-out") && (
                  <Box>
                    <Typography className={styles.label}>In Time</Typography>
                    <RHFTimePicker name="inTime" />
                  </Box>
                )}

                {(selectedType === "out" || selectedType === "in-out") && (
                  <Box>
                    <Typography className={styles.label}>Out Time</Typography>
                    <RHFTimePicker name="outTime" />
                  </Box>
                )}
              </Stack>
              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="reason"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={""}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Apply
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
