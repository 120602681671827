import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import Attendence from "./Attendence/Attendence";
import LeaveManagement from "./LeaveManagement/LeaveManagement";
import Notices from "./Notices/Notices";
import Policies from "./Policies/Policies"; 
import presentIcon from "../../styles/icons/present.svg"
import lateComingIcon from "../../styles/icons/lateComing.svg"
import totalLeave from "../../styles/icons/totalLeave.svg"
import avrWorkHour from "../../styles/icons/avgWorkHour.svg"
import { LateComingQuery } from "../../utils/reactQuery/LateComing";
import { PresentMonthLeaves } from "../../utils/reactQuery/PresentMonthLeaves";
import { PreviousMonthLeavesQuery } from "../../utils/reactQuery/PreviousMonthLeaves";
import { ShortDaysQuery } from "../../utils/reactQuery/ShortDays";
import Navbar from "../../components/Navbar/Navbar";


export default function Dashboard() {
  const {lateComing} = LateComingQuery();
  const {presentMonthLeaves} = PresentMonthLeaves();
  const {previousMonthLeaves} = PreviousMonthLeavesQuery();
  const {shortDays} = ShortDaysQuery();

  return (
    <>
    <Navbar/>
    <Box className={styles.mainContainer}>
      <Stack className={styles.container} direction="row">
        <Typography className={styles.heading}>Dashboard</Typography>
      </Stack>

      <Stack direction="row" className={styles.cardWrapper}>
        <Stack className={styles.cardCont} direction="row">
          <img className={styles.icons} src={presentIcon} alt="present" />
          <Box className={styles.textCont}>
            <Typography className={styles.cardHead}>Leave this month</Typography>
            <Typography className={styles.cardContent}>{presentMonthLeaves%1>0?presentMonthLeaves:presentMonthLeaves<10?`0${presentMonthLeaves}`:presentMonthLeaves}</Typography>
          </Box>
        </Stack>

        <Stack className={styles.cardCont} direction="row">
          <img className={styles.icons} src={lateComingIcon}/>
          <Box className={styles.textCont}>
            <Typography className={styles.cardHead}>Leave last month</Typography>
            <Typography className={styles.cardContent}>{previousMonthLeaves%1>0? previousMonthLeaves : previousMonthLeaves<10?`0${previousMonthLeaves}`:previousMonthLeaves}</Typography>
          </Box>
        </Stack>

        <Stack className={styles.cardCont} direction="row">
          <img className={styles.icons} src={totalLeave}/>
          <Box className={styles.textCont}>
            <Typography className={styles.cardHead}>Late days</Typography>
            <Typography className={styles.cardContent}>{lateComing%1>0?lateComing:lateComing<10?`0${lateComing}`:lateComing}</Typography>
          </Box>
        </Stack>

        <Stack className={styles.cardCont} direction="row">
          <img className={styles.icons} src={avrWorkHour}/>
          <Box className={styles.textCont}>
            <Typography className={styles.cardHead}>
              No. of short days
            </Typography>
            <Typography className={styles.cardContent}>{shortDays%1>0?shortDays:shortDays<10?`0${shortDays}`:shortDays}</Typography>
          </Box>
        </Stack>
      </Stack>

      <Attendence />

      <LeaveManagement />

      <Stack direction="row" className={styles.noticePolicieCont}>
        <Box className={styles.sectionCont}>
          <Notices />
        </Box>

        <Box className={styles.sectionCont}>
          <Policies />
        </Box>
      </Stack>
    </Box>
    </>
  );
}
