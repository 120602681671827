import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import DataGridComp from "../../../../components/DataGrid/DataGrid";
import styles from "./styles.module.css";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { LeaveStatementQuery } from "../../../../utils/reactQuery/leave/LeaveStatement";
import { getFinancialYear } from "../../../../services/leave/apiFinancialYear";
import { FinancialYearQuery } from "../../../../utils/reactQuery/leave/FinancialYear";

function LeaveStatement() {
  const [currentYear, setCurrentYear] = useState(`${new Date().getFullYear()}-${new Date().getFullYear()+1}`);
  const isMobile = useResponsive("down", "sm");

  const { leaveStatement, isLoading } = LeaveStatementQuery(currentYear);

  const { FinancialYear } = FinancialYearQuery();

  const columns = [
    {
      field: "col1",
      headerName: "Month & Year",
      minWidth: isMobile ? 101 : 127,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "CL",
      minWidth: isMobile ? 100 : 127,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "CL",
      minWidth: isMobile ? 101 : 126,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "CL Used",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "LC",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "EL",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col7",
      headerName: "LWP",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col8",
      headerName: "Encashed",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col9",
      headerName: "Comp Off",
      minWidth: isMobile ? 100 : 120,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col10",
      headerName: "CL",
      minWidth: isMobile ? 100 : 127,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "2024-2025",
      description: "",
      children: [{ field: "col1" }],
    },
    {
      groupId: "Opening Balance",
      description: "",
      children: [{ field: "col2" }],
    },
    {
      groupId: "Alloted",
      description: "",
      children: [{ field: "col3" }],
    },
    {
      groupId: "Adjustments",
      children: [
        { field: "col4" },
        { field: "col5" },
        { field: "col6" },
        { field: "col7" },
        { field: "col8" },
        { field: "col9" },
      ],
    },
    {
      groupId: "Closing Balance",
      description: "",
      children: [{ field: "col10" }],
    },
  ];

  useEffect(() => {
    getFinancialYear()
      .then(() => {})
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box className={styles.cont}>
      <Stack direction="row" className={styles.container}>
        <Stack className={styles.shortFormCont} direction="row">
          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>CL </b>- Casual Leave
            </Typography>
            <Typography></Typography>
          </Stack>

          <Stack
            direction="row"
            className={[styles.subCont, styles.lateComMobile]}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>LC </b>- Late Coming
            </Typography>
            <Typography></Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>EL </b>- Early Leaving
            </Typography>
            <Typography></Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>LWP </b>- Leave Without Pay
            </Typography>
            <Typography></Typography>
          </Stack>
        </Stack>

        <Stack direction="row" className={styles.financialSection}>
          <Typography className={styles.labelFinance}>
            Select Financial Year
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentYear}
            onChange={(event) => {
              setCurrentYear(event.target.value);
            }}
            className={styles.selectField}>
            {FinancialYear
              ? FinancialYear.map((ele,idx) => (
                  <MenuItem className={styles.menuItem} value={ele.value} key={idx}>
                    {ele.year}
                  </MenuItem>
                ))
              : []}
          </Select>
        </Stack>
      </Stack>

      <Box className={styles.datagridCon}>
        {isLoading ? (
          <Stack className={styles.loadingCont}>
            <CircularProgress />
          </Stack>
        ) : (
          ""
        )}
        <DataGridComp
          columns={columns}
          initialRows={leaveStatement ? leaveStatement : []}
          // total={data?.data.total}
          headerBgColor={"#fff"}
          columnGroupingModel={columnGroupingModel}
          footer={true}
          pageSize={'12'}
        />
      </Box>
    </Box>
  );
}

export default LeaveStatement;
