import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomTextfied from '../../Textfield/CustomTextfied'
import styles from "./styles.module.css";
import { PersonalInfoQuery } from '../../../utils/reactQuery/profile/PersonalInfo';
import { useResponsive } from '../../../hooks/customUseResponsive';
import ManagerProfileInfoQuery from '../../../utils/reactQuery/manager/employeeDetail/ManagerProfileInfo';

export default function PersonalInfo({managerId}) {
  const {employee, isLoading} = managerId ? ManagerProfileInfoQuery(managerId) : PersonalInfoQuery();

  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {isLoading ? '':<Stack className={styles.emplyeIdCont}>
      <Typography className={styles.labels}>Employee ID </Typography>
      <Typography className={styles.emplyeId}>{employee?.id?employee.id:''}</Typography>
      </Stack>}
      {isLoading ? <Stack className={styles.loaderCont}>
        <CircularProgress/>
      </Stack>:
          <Grid container spacing={isMobile ? 1 :2}>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Employee Name</Typography>
              <CustomTextfied value={employee?.name ? employee.name :''}/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Father's Name</Typography>
              <CustomTextfied value={employee?.father_name?employee.father_name:''}/>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Mother's Name</Typography>
              <CustomTextfied value={employee?.mother_name?employee.mother_name:''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Date of Birth</Typography>
              <CustomTextfied value={employee?.dob?employee.dob:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Gender</Typography>
              <CustomTextfied value={employee?.gender?employee.gender:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Qualification</Typography>
              <CustomTextfied value={employee?.qualification?employee.qualification:''}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={isMobile ? 1 :2}>
                <Grid item xs={12} sm={6}>
                  <Typography className={styles.labels}>Experience</Typography>
                  <CustomTextfied value={employee?.experience?employee.experience:''}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={styles.labels}
                    sx={{ visibility: "hidden" }}>
                    Experience
                  </Typography>
                  <CustomTextfied value={employee?.experience?employee.experience:''} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Technology</Typography>
              <CustomTextfied value={employee?.technology?employee.technology:''}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>PAN Number</Typography>
              <CustomTextfied value={employee?.pan_card?employee.pan_card:''}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>PF Account</Typography>
              <CustomTextfied value={employee?.pf_account?employee.pf_account:''}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>ESIC No.</Typography>
              <CustomTextfied value={employee?.esic_no?employee.esic_no:''}/>
            </Grid>
          </Grid>
          }
        </Box>
  )
}
