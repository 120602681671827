import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/customUseResponsive";
import ViewMessageLeave from "../../utils/reactQuery/manager/ViewMessageLeave";

export default function ManagerNote({ openPop, handleClose, id }) {
  const isMobile = useResponsive("down", "sm");
  const { reason, isLoading } = ViewMessageLeave(id);

  console.log("leave message", reason);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 507,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
    maxHeight: '90vh',
    overflowX: 'auto'
  };
  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>Notes</Typography>
          <Box onClick={() => handleClose()}>
            <CloseIcon className={styles.icon} />
          </Box>
        </Stack>
        {isLoading ? (
          <Stack className={styles.loaderCont}>
            <CircularProgress />
          </Stack>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: reason }}
            className={styles.reason}></div>
        )}
        <Stack className={styles.btnCont}>
          <Button
            className={styles.btnClass}
            onClick={handleClose}
            variant="contained"
            color="primary">
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
