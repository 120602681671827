import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import EmployeeDetailQuery from "../../../utils/reactQuery/manager/employeeDetail/EmployeeDetail";
import viewIcon from "../../../styles/icons/viewicon.svg";
import Profile from "../../../components/Profile/Profile";

export default function AdminPolicies() {
  const { employeeList, isLoading } = EmployeeDetailQuery();
  const selectedId = useRef("");
  const [profileModal, setProfileModal] = useState(false);

  const handleProfile = (id) => {
    selectedId.current = id;
    setProfileModal(true);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Subject",
      minWidth: 127,
      flex: 1.7,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Create Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Action",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack
          className={styles.iconsCont}
          onClick={() => handleProfile(value.value)}>
          <img className={styles.icon} src={viewIcon} />
        </Stack>
      ),
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      <span id="employeeDetail" className={styles.employeeDetail}></span>

      <Stack className={styles.headerContainer}>
        <Typography className={styles.heading}>Policies</Typography>

        <Button
          className={styles.btnClass}
          onClick={() => ""}
          variant="contained"
          color="primary">
          Add Policy
        </Button>
      </Stack>
      <DataGridComp
        columns={columns}
        initialRows={employeeList ? employeeList : []}
      />
      {selectedId ? (
        <Profile
          openProp={profileModal}
          handleClose={() => setProfileModal(false)}
          managerId={selectedId.current}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
