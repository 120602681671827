import { TextField } from '@mui/material'
import React from 'react'
import { useResponsive } from '../../hooks/customUseResponsive'


export default function CustomTextfied({value}) {
  const isMobile = useResponsive('down', 'sm');
  return (
    <TextField
                sx={{ '& .MuiOutlinedInput-input': { padding: isMobile ? '6px' :'10px',
                    color: '#6C757D',
                    backgroundColor: '#fff',
                    border: '1.5px solid #CED4DA',
                    borderRadius: '4px',
                    fontSize: isMobile ? '12px':'14px',
                    fontWeight: 400
                 } }}
                fullWidth
                variant="outlined"
                value={value}
                InputProps={{ readOnly: true }}
              />
  )
}
