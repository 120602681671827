import Dashboard from "../../pages/Dashboard/Dashboard";
import ManageTeam from "../../pages/ManageTeam/MangeTeam";
import Private from "./private";

export const Pages={
    element: <Private/>,
    children:[{
        element: <Dashboard/>,
        path: '/dashboard'
    },{
        element: <ManageTeam/>,
        path: '/manage'
    }]
}