import axios from "axios";
import { localStorageInstance } from "../utils/localStorage";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "",
});

Api.interceptors.request.use(function (response) {
  const token = localStorageInstance.getItem("token");

  if (token !== undefined && token !== null) {
    response.headers["Authorization"] = `Bearer ${token}`;
  }
  return response;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401 && window.location.pathname !== "/") {

      localStorageInstance.clear();
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
    }
    return Promise.reject(error);
  }
);

export default Api;
