import {
  Box,
  Button,
  Modal,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import ContactInfo from "./ContactInfo/ContactInfo";
import { useResponsive } from "../../hooks/customUseResponsive";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#1B3E61 !important",
          height: "4px", // Increase the height (thickness) of the indicator
        },
      },
    },
  },
});

export default function Profile({ openProp, handleClose, managerId }) {
  const [value, setValue] = useState("personalInfo");

  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
  };

  const handleChange = (event, value) => {
    setValue(value);
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal open={openProp} onClose={handleClose}>
        <Box sx={{ ...style }} className={styles.container}>
          <Stack direction="row" className={styles.header}>
            <Typography className={styles.heading}>Profile Update</Typography>
            <Box onClick={() => handleClose()}>
              <CloseIcon className={styles.icon} />
            </Box>
          </Stack>
          <Box className={styles.borderBtm}></Box>
          <Box className={styles.tabCont}>
            <Tabs
              aria-label="basic tabs example"
              value={value}
              onChange={handleChange}
              className={styles.tabContainer}>
              <Tab
                label="Personal Information"
                className={styles.tabHead}
                value="personalInfo"
              />
              <Tab
                label="Contact Information"
                className={styles.tabHead}
                value="contactInfo"
              />
            </Tabs>
          </Box>

          {value === "personalInfo" ? (
            <PersonalInfo managerId={managerId} />
          ) : (
            <ContactInfo managerId={managerId}/>
          )}
          <Stack className={styles.btnCont}>
            <Button
              variant="contained"
              onClick={() => handleClose()}
              className={styles.btn}>
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
