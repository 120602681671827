import Login from "../../pages/auth/Login";
import Protected from "./protected";

export const Auth = {
        element: <Protected/>,
        children:[
            {
                element: <Login />,
                path: '/'
            }
        ]
}