import { useQuery } from "@tanstack/react-query"
import { getUserDetails } from "../../services/profile/apiShowData"

export const ShowUserData =()=>{
    const { data, isLoading, isError} = useQuery({
        queryKey: ['ShowUserData'],
        queryFn: ()=>getUserDetails()
    })

    let user = data?.data[0];

    return {user}
}