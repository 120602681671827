import { useQuery } from "@tanstack/react-query"
import { getMissingEntryCount } from "../../../../services/manager/count/apiMIssingEntryCount"
import { getInOutMissingEntry } from "../../../../services/manager/count/apiInoutMissingEntry"

export const InOutMissingEntry=({id, date})=>{
    const {data, isLoading} = useQuery({
        queryKey: ['InOutMissingEntry',id,date],
        queryFn: ()=>getInOutMissingEntry({id,date})
    })

    const list = data?.data

    return {list, isLoading}
}