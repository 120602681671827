export function removeHtmlTagsAndEntities(str) {
    // Regular expression to match HTML tags
    var htmlTagPattern = /<[^>]*>/g;
    
    // Regular expression to match HTML entities
    var htmlEntityPattern = /&[^;]+;/g;
    
    // Replace HTML tags with an empty string
    var cleanStr = str.replace(htmlTagPattern, '');
    
    // Replace HTML entities with an empty string
    cleanStr = cleanStr.replace(htmlEntityPattern, '');
    
    return cleanStr;
}