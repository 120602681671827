import { useQuery } from "@tanstack/react-query"
import { getShortDayList } from "../../../services/manager/count/apiShortDayList"
import { usDateFormate } from "../../../hooks/dateFormat"

export const ShortDayListQuery=({id, startDate, endDate})=>{
    const {data, isLoading, refetch} = useQuery({
        queryKey: ['shortDayList', id, startDate, endDate],
        queryFn: ()=>getShortDayList({id, startDate, endDate})
    })
    const shortDayEmployee = data?.data.short_days_list.map((ele,idx)=>{
        return{
            id: idx,
            col1: ele.employee_name,
            col2: usDateFormate(ele.date),
            col3: ele.total_hours
        }
    })
    return {shortDayEmployee, isLoading, refetch}
}