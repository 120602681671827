import { useQuery } from "@tanstack/react-query";
import getNoticeList from "../../../services/notice/apiNotices";

export function NoticesListQuery(){
    const {data, isLoading, isError,refetch} = useQuery({
        queryKey: ['noticeList'],
        queryFn: ()=> getNoticeList()
    })

    const noticeList = data?.data;

    return {noticeList, refetch, isLoading, isError}
}