import { useQuery } from "@tanstack/react-query"
import { getPersonalInfo } from "../../../services/profile/apiPersonalInfo";

export const PersonalInfoQuery = ()=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['personalInfo'],
        queryFn: ()=> getPersonalInfo()
    })
    const employee = data?.data.employee
    return { employee, isLoading, isError};
}