import React from "react";
import { Typography, Stack, Divider, Menu } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import styles from "./styles.module.css";
import manageTeamIcon from "../../styles/icons/manageTeamIcon.svg";
import myDashBoardIcon from "../../styles/icons/myDashboardIcon.svg";
import { useNavigate } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";

export default function AccountPopOver({
  openpop,
  handleClose,
  handleProfile,
  handleLogout,
  anchorEl,
}) {
  const navigate = useNavigate();

  const handleManageTeam = () => {
    navigate("/manage");
    handleClose();
  };

  const handleMyDashboard = () => {
    navigate("/dashboard");
    handleClose();
  };

  const isManger = localStorageInstance.getItem("role") === "manager";

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={openpop}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
      {isManger || localStorageInstance.getItem("role") === "admin" ? (
        <span>
          <Stack className={styles.avatarTxtCont} onClick={handleMyDashboard}>
            <img
              src={myDashBoardIcon}
              className={styles.dropDownIcon}
              alt="drop down"
            />
            <Typography className={styles.weight500}>My Dashboard</Typography>
          </Stack>

          <Divider />

          <Stack className={styles.avatarTxtCont} onClick={handleManageTeam}>
            <img
              src={manageTeamIcon}
              className={styles.dropDownIcon}
              alt="drop down"
            />
            <Typography className={styles.weight500}>Manage Team</Typography>
          </Stack>

          <Divider />
        </span>
      ) : (
        ""
      )}

      <Stack className={styles.avatarTxtCont} onClick={() => handleProfile()}>
        <AccountCircleOutlinedIcon className={styles.dropDownIcon} />
        <Typography className={styles.weight500}>Update Profile</Typography>
      </Stack>

      <Divider />

      <Stack className={styles.avatarTxtCont} onClick={() => handleLogout()}>
        <PowerSettingsNewOutlinedIcon className={styles.dropDownIcon} />
        <Typography className={styles.weight500}>Logout</Typography>
      </Stack>
    </Menu>
  );
}
