import { useQuery } from "@tanstack/react-query"
import { getManagerContactInfo } from "../../../../services/manager/managerProfile/apiManagerContactInfo"

export const ManagerContactInfoQuery=(id)=>{
    const {data, isLoading} = useQuery({
        queryKey: ['managerContactInfo', id],
        queryFn: ()=>getManagerContactInfo({id})
    })
    const contact = data?.data.contact_info
    return {contact, isLoading}
}