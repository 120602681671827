import { useQuery } from "@tanstack/react-query"
import { getViewManualMessage } from "../../../services/manager/attendance/apiViewManualEntry";


export const ViewManualMessageQuery = (id)=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['viewManualMessage',id],
        queryFn: ()=> getViewManualMessage(id)
    })
    var note="";
    if(data){
        note = {description: data?.data[0].reason}
    }

    console.log("object === ", note)
    return {note, isLoading, isError};
}