import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomTextfied from '../../Textfield/CustomTextfied'
import styles from "./styles.module.css";
import { ContactInfoquery } from '../../../utils/reactQuery/profile/ContactInfo';
import { useResponsive } from '../../../hooks/customUseResponsive';
import { ManagerContactInfoQuery } from '../../../utils/reactQuery/manager/employeeDetail/ManagerContactInfo';

export default function ContactInfo({managerId}) {
  const {contact, isLoading} = managerId ? ManagerContactInfoQuery(managerId) : ContactInfoquery();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
          {isLoading ? <Stack className={styles.loaderCont}>
            <CircularProgress/>
          </Stack> :<Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Mobile 1</Typography>
              <CustomTextfied value={contact?.phone_number?contact.phone_number: ''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Mobile 2</Typography>
              <CustomTextfied value={contact?.alternate_phone_number?contact.alternate_phone_number:''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Temporary Address</Typography>
              <CustomTextfied value={contact?.temporary_address?contact.temporary_address:''} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Permanent Address</Typography>
              <CustomTextfied value={contact?.permanent_address?contact.permanent_address:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>City</Typography>
              <CustomTextfied value={contact?.city?contact.city:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>State</Typography>
              <CustomTextfied value={contact?.state?contact.state:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Country</Typography>
              <CustomTextfied value={contact?.country?contact.country:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Aadhaar Card</Typography>
              <CustomTextfied value={contact?.adhar_card?contact.adhar_card:''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>Driver License</Typography>
              <CustomTextfied value={contact?.driver_license?contact.driver_license:''} />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography className={styles.labels}>PAN Card</Typography>
              <CustomTextfied value={contact?.pan_card?contact.pan_card:''} />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <Typography className={styles.labels}>Passport Size Photo</Typography>
              <Stack className={styles.passportCont}>
                <Typography className={styles.labels}>No image found</Typography>
              </Stack>
            </Grid>
          </Grid>}

        </Box>
  )
}
