import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader"

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import InOutModal from '../../../components/InOutModal/InOutModal'

import forwardIcon from "../../../styles/icons/forward.svg";
import backwardIcon from "../../../styles/icons/back.svg";
import { AttendanceQuery } from "../../../utils/reactQuery/attendance/AttendanceData";
import { useResponsive } from "../../../hooks/customUseResponsive";

export default function Attendance() {
  const calendarRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openInOutModal, setInOutModal] = useState(false);
  const [dateInOut, setDateInOut] = useState("");
  const [inoutWeek, setInoutWeek] = useState("");
  const isMobile = useResponsive("down", "sm");
  
  // Fetch attendance data
  const { attendanceList: event, isLoading } = AttendanceQuery({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
  });

  // Month names array for display purposes
  const monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Handle calendar dates set event
  const handleDatesSet = (dateInfo) => {
    setCurrentDate(new Date());
  };

  // Handle previous month button click
  const handlePrevClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  // Handle next month button click
  const handleNextClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  // Get current month and year
  const getCurrentMonthYear = () => {
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return { month, year };
  };

  const { month, year } = getCurrentMonthYear();

  // Render event content in the calendar
  function renderEventContent(eventInfo) {
    // console.log("event === ", eventInfo)
    const handleInOutModal = (date) => {
      setInOutModal(true);
      setDateInOut(date);
      setInoutWeek(eventInfo.event.start.getDay());
    };
    return (
      <Typography
        onClick={() => handleInOutModal(eventInfo.event.startStr)}
        className={`${
          styles.statusChip} 
          ${eventInfo.event.title === "Leave"
            ? styles.leaveStatus
            : eventInfo.event.title === "Short Day"
            ? styles.shortDayStatus
            : eventInfo.event.title === "Holiday"
            ? styles.holidayStatus
            : eventInfo.event.title === "Present"
            ? styles.presentStatus
            : eventInfo.event.title === "Comp_Off"
            ? styles.compOffStatus
            : styles.missedEntry}`}>
        {isMobile
          ? eventInfo.event.title === "Leave"
            ? "LE"
            : eventInfo.event.title === "Short Day"
            ? "SD"
            : eventInfo.event.title === "Holiday"
            ? "HD"
            : eventInfo.event.title === "Present"
            ? "PR"
            : eventInfo.event.title === "Comp_Off"
            ? "CO"
            : "ME"
          : eventInfo.event.title}
      </Typography>
    );
  }

  return (
    <Box className={styles.mainContainer}>
      <TableHeader heading={"Attendance"} />

      {isMobile ? (
        <Box className={styles.initialsCont}>
          <Stack className={styles.shortFormCont} direction="row">
            <Stack direction="row" className={styles.subCont}>
              <Box className={styles.indicator} />
              <Typography className={styles.shortFormText}>
                <b>PR </b>- Present
              </Typography>
              <Typography></Typography>
            </Stack>

            <Stack
              direction="row"
              className={[styles.subCont, styles.lateComMobile]}>
              <Box className={styles.indicator} />
              <Typography className={[styles.shortFormText]}>
                <b>LE </b>- Leave
              </Typography>
              <Typography></Typography>
            </Stack>

            <Stack direction="row" className={styles.subCont}>
              <Box className={styles.indicator} />
              <Typography className={styles.shortFormText}>
                <b>SD </b>- Short Days
              </Typography>
              <Typography></Typography>
            </Stack>

            <Stack
              direction="row"
              className={[styles.subCont, styles.missingEntry]}>
              <Box className={styles.indicator} />
              <Typography className={styles.shortFormText}>
                <b>ME </b>- Missing Entry
              </Typography>
              <Typography></Typography>
            </Stack>

            <Stack direction="row" className={styles.subCont}>
              <Box className={styles.indicator} />
              <Typography className={styles.shortFormText}>
                <b>CO </b>- Comp Off
              </Typography>
              <Typography></Typography>
            </Stack>

            <Stack direction="row" className={styles.subCont}>
              <Box className={styles.indicator} />
              <Typography className={styles.shortFormText}>
                <b>HD </b>- Holiday
              </Typography>
              <Typography></Typography>
            </Stack>
          </Stack>
        </Box>
      ) : (
        ""
      )}

      <Box className={styles.tabContainer}>
        <Stack className={styles.calendarCont}>
          <Stack className={styles.monthYearCont}>
            <Typography className={styles.month}>
              {monthNames[month]}
            </Typography>
            <Typography className={styles.year}>{year}</Typography>
          </Stack>

          <Stack className={styles.calenderBtn}>
            <Button
              disabled={isLoading}
              className={styles.arrowBtn}
              onClick={handlePrevClick}>
              <img className={styles.icons} src={backwardIcon} alt="backward"/>
            </Button>

            <Button
              disabled={
                isLoading || currentDate.getMonth() === new Date().getMonth()
              }
              className={
                currentDate.getMonth() === new Date().getMonth()
                  ? styles.disableNextArrow
                  : styles.arrowBtn
              }
              onClick={handleNextClick}>
              <img className={styles.icons} src={forwardIcon} alt="forward"/>
            </Button>
          </Stack>
        </Stack>

        {isLoading ? (
          <Stack className={styles.calendarLoader}>
            <CircularProgress />
          </Stack>
        ) : (
          ""
        )}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={false}
          datesSet={handleDatesSet}
          events={event}
          eventContent={renderEventContent}
          fixedWeekCount={false}
        />
      </Box>
      {openInOutModal ? (
        <InOutModal
          openProp={openInOutModal}
          handleClose={() => setInOutModal(false)}
          date={dateInOut}
          day={inoutWeek}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
