import { useQuery } from "@tanstack/react-query";
import { getUpcomingLeaves } from "../../../services/manager/apiUpcomingLeaves";
import { usDateFormate } from "../../../hooks/dateFormat";
import { removeHtmlTagsAndEntities } from "../../../hooks/removehtmlTags";

export default function UpcomingLeaveQuery(){
    const {data, isLoading} = useQuery({
        queryKey: ['upcomingLeave'],
        queryFn: ()=>getUpcomingLeaves()
    })
    let upcomingLeaves = data?.data.leave_logs.map((ele,idx)=>{
        return {
            id: idx,
            col1: ele.employee_name,
            col2: usDateFormate(ele.start_date),
            col3: usDateFormate(ele.end_date),
            col4: ele.total_days,
            col5: usDateFormate(ele.applied_on),
            col6: removeHtmlTagsAndEntities(ele.reason)
        }
    })

    return {upcomingLeaves, isLoading};
}