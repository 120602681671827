import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import CompOffRequestQuery from "../../../utils/reactQuery/manager/compoOff/CompOffRequest";
import rejectIcon from "../../../styles/icons/crossIcon.svg";
import approvedIcon from "../../../styles/icons/approvedIcon.svg";
import viewIcon from "../../../styles/icons/viewicon.svg";
import ManagerNote from "../../../components/managerNote/ManagerNote";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postApproveRequest } from "../../../services/manager/compOff/apiApproveRequest";
import RejectRequestModal from "../../../components/RejectRequestModal";
import { postRejectCompoffRequest } from "../../../services/manager/compOff/apiRejectCompoffRequest";
import AddCompOffModal from "./AddCompOff";

export default function CompOffRequest() {
  const { CompOffList, refetch } = CompOffRequestQuery();
  const [notePopover, setNotePopover] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectRequestModal, setRejectRequestModal] = useState(false);
  const [modals, setModals] = useState({ addCompOffModal: false });
  const queryClient = useQueryClient();
  const listOfEmp = queryClient.getQueryData(["employeeUnderManager"])?.data;

  const { mutate, isPending } = useMutation({
    mutationFn: postApproveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.log("error employee feedback", data);
    },
  });

  const { mutate: mutateRejectRequest } = useMutation({
    mutationFn: postRejectCompoffRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.log("error employee feedback", data);
    },
  });

  const handleView = (id) => {
    setNotePopover(true);
    setSelectedId(id);
  };

  const handleApproveApplication = (id) => {
    setSelectedId(id);
    setApproveModal(true);
  };

  const confirmApproveApplication = () => {
    mutate(selectedId);
    setApproveModal(false);
  };

  const handleRejectApplication = (id) => {
    setSelectedId(id);
    setRejectRequestModal(true);
  };

  const handleRejectRequest = (reason) => {
    mutateRejectRequest({ id: selectedId, reason });
    setRejectRequestModal(false);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1.7,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Work Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Leave Date",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Applied On",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }>
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col6",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleView(value.value.id)}
            alt="view icon"
          />
          {!value.value.isPast &&
            (value?.value.status === "Pending" ? (
              <>
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve icon"
                />
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject icon"
                />
              </>
            ) : value?.value.status == "Rejected" ? (
              <img
                className={styles.icon}
                src={approvedIcon}
                onClick={() => handleApproveApplication(value.value.id)}
                alt="approve"
              />
            ) : (
              <img
                className={styles.icon}
                src={rejectIcon}
                onClick={() => handleRejectApplication(value.value.id)}
                alt="reject icon"
              />
            ))}
        </Stack>
      ),
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      <span id="compOffRequest" className={styles.compOffRequest}></span>

      <Stack className={styles.headerContainer}>
        <Typography className={styles.heading}>CompOff Request</Typography>

        <Button
          className={styles.btnClass}
          onClick={() => setModals({...modals,addCompOffModal: true})}
          variant="contained"
          color="primary">
          Add Employee CompOff
        </Button>
      </Stack>
      <DataGridComp
        columns={columns}
        initialRows={CompOffList ? CompOffList : []}
      />
      {notePopover ? (
        <ManagerNote
          openPop={notePopover}
          handleClose={() => setNotePopover(false)}
          id={selectedId}
        />
      ) : (
        ""
      )}
      {approveModal ? (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproveApplication()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      ) : (
        ""
      )}
      <RejectRequestModal
        openProp={rejectRequestModal}
        handleClose={() => setRejectRequestModal(false)}
        handleConfirm={handleRejectRequest}
      />
      {modals.addCompOffModal && (
        <AddCompOffModal
          openProp={modals.addCompOffModal}
          handleClose={() => setModals({ ...modals, addCompOffModal: false })}
          listOfEmp={listOfEmp}
        />
      )}
    </Box>
  );
}
