import { useQuery } from "@tanstack/react-query"
import { getContactInfo } from "../../../services/profile/apiContactInfo";

export const ContactInfoquery = ()=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['contactInfo'],
        queryFn: ()=> getContactInfo()
    })
    const contact = data?.data.contact_info;
    return {contact, isLoading, isError};
}