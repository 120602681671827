import { useQuery } from "@tanstack/react-query"
import { getMissingEntryCount } from "../../../../services/manager/count/apiMIssingEntryCount"

export const MissingEntryCount=()=>{
    const {data, isLoading: missingEntryLoading} = useQuery({
        queryKey: ['missingEntryCount'],
        queryFn: ()=>getMissingEntryCount()
    })

    const missingEntryCount = data?.data.missing_entry_count

    return {missingEntryCount, missingEntryLoading}
}