import React, { useState } from "react";
import styles from "./styles.module.css";
import { Box, Tab, Tabs } from "@mui/material";
import TableHeader from "../../../components/TableHeader/TableHeader";
import LeaveStatement from "./LeaveStatement/LeaveStatement";
import LeaveLog from "./LeaveLog/LeaveLog";
import AddLeaveModal from "./AddLeaveModal/AddLeaveModal";

export default function LeaveManagement() {
  const [value, setValue] = useState("leaveStatement");
  const [addleaveModal, setAddleaveModal] = useState(false);

  const handleChange = (event,value) => {
    setValue(value);
  };

  return (
    <>
      <Box className={styles.mainContainer}>
      <Box className={styles.border}></Box>

        <TableHeader heading={"Leave Management"} btntxt={"Add Leave"} handleModal={()=>setAddleaveModal(true)}/>
        <Box className={styles.tabContainer}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            aria-label="basic tabs example"
            value={value}
            onChange={handleChange}
            className={styles.tabCont}>
            <Tab
              label="Leave Statement"
              className={styles.tabHead}
              value="leaveStatement"
            />
            <Tab
              label="Leave Logs"
              className={styles.tabHead}
              value="leaveLogs"
            />
          </Tabs>
        </Box>
        </Box>
        <Box>
          {value === "leaveStatement" ? <LeaveStatement/>: <LeaveLog/>}
        </Box>
        <AddLeaveModal openProp={addleaveModal} handleClose={()=>setAddleaveModal(false)}/>
      </Box>
      
    </>
  );
}
