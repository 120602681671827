import { useQuery } from "@tanstack/react-query";
import getPreviousMonthLeave from "../../services/count/apiPreviousMonthLeaves";

export function PreviousMonthLeavesQuery(){
    const {data, isLoading, isError} = useQuery({
        queryKey: ['previousMonthLeaves'],
        queryFn: ()=> getPreviousMonthLeave()
    })

    const previousMonthLeaves = data?.data.total_leave;

    return {previousMonthLeaves, isLoading, isError}
}