import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";

export default function RHFFileUploader({
  name,
  label,
  accept = "image/jpeg,image/png",
  helperText,
  placeholder,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} {...other}>
          {label && <InputLabel shrink>{label}</InputLabel>}
          <Box>
            <input
              type="file"
              accept={accept}
              onChange={(e) => field.onChange(e.target.files)}
              style={{ display: 'block', marginTop: 8, marginBottom: 4 }}
            />
            {!field.value?.length && placeholder && (
              <Box sx={{ color: 'text.disabled', marginTop: 1 }}>
                {placeholder}
              </Box>
            )}
          </Box>

          {(!!error || helperText) && (
            <FormHelperText>{error ? error.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
