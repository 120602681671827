import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { ViewFeedbackQuery } from "../../../../utils/reactQuery/manager/EmployeeFeedback/ViewFeedback";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFeedbackSchema } from "../../../../schemas/addFeedback";
import ReactQuill from "react-quill";
import { RHFSelect } from "../../../../components/RFH/RFHselect";
import { removeHtmlTagsAndEntities } from "../../../../hooks/removehtmlTags";
import { putEditFeedback } from "../../../../services/manager/EmployeeFeedback/apiEditFeedback";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

export default function EditFeedback({ openProp, handleClose, id }) {
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "sm");
  const [feedbackState, setFeedbackState] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1097,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    scrollY: "scroll",
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }], // Add color and background color options
      ["link"],
      ["clean"],
    ],
  };

  const { mutate, isPending } = useMutation({
    mutationFn: putEditFeedback,
    onSuccess: (data) => {
      console.log("put edit ", data);
      toast.success(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["employeeFeedbackList"]
      });
      queryClient.invalidateQueries({
        queryKey: ["viewFeedback", id]
      });
      handleClose();
    },
    onError: (data) => {
      console.log("error employee feedback", data);
    },
  });

  const { viewFeedback, isLoading } = ViewFeedbackQuery(id);

  const methods = useForm({
    resolver: yupResolver(addFeedbackSchema),
    defaultValues: {
      employeeName: viewFeedback?.employee_name,
      quarter: viewFeedback?.quarter,
      feedback: viewFeedback?.description,
    },
  });

  const onSubmit = (formData) => {
    console.log("on submit edit feedback", formData);
    mutate({
      id: id,
      description: formData.feedback,
      quarter: formData.quarter,
    });
  };

  const rhfSelect = useMemo(() => {
    return (
      <RHFSelect name="quarter">
        {[
          <MenuItem value="quarter1" key="1">
            Quarter 1
          </MenuItem>,

          <MenuItem value="quarter2" key="2">
            Quarter 2
          </MenuItem>,

          <MenuItem value="quarter3" key="3">
            Quarter 3
          </MenuItem>,

          <MenuItem value="quarter4" key="4">
            Quarter 4
          </MenuItem>,
        ]}
      </RHFSelect>
    );
  }, [viewFeedback?.quarter]);

  useEffect(() => {
    if (viewFeedback) {
      methods.setValue("employeeName", viewFeedback.employee_name);
      methods.setValue("quarter", viewFeedback.quarter);
      methods.setValue("feedback", viewFeedback.description);

      setFeedbackState(viewFeedback);

      console.log(" useEffect clicked", viewFeedback);
    }
  }, [viewFeedback, methods]);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box className={styles.container}>
              <Box className={styles.greenTab} />
              <Stack direction="row" className={styles.headerContainer}>
                <Typography className={styles.tableHead}>
                  {viewFeedback?.employee_name}
                </Typography>
                <CloseIcon
                  sx={{ color: "#fff", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Stack>
            </Box>
            {isLoading ? (
              <Stack className={styles.loaderCont}>
                <CircularProgress />
              </Stack>
            ) : (
              <Stack className={styles.bodyContainer}>
                <Stack className={styles.subCont}>
                  <Typography className={styles.label}>
                    Review Period:{" "}
                  </Typography>
                  <Box className={styles.selectCont}>{rhfSelect}</Box>
                </Stack>

                <Divider />

                <Box className={styles.textEditorCont}>
                  <Typography className={styles.label}>Feedback:</Typography>
                  <ReactQuill
                    value={methods.getValues("feedback")}
                  onChange={(value) =>
                    methods.setValue(
                      "feedback",
                      removeHtmlTagsAndEntities(value).length === 0 ? "" : value
                    )
                  }
                    modules={modules}
                    style={{ height: "160px", marginTop: '10px'}}
                  />
                </Box>
                <Typography className={styles.feedbackError}>
                  {methods.formState.errors.feedback?.message}
                </Typography>

                <LoadingButton
                  loading={isPending}
                  className={styles.btnClass}
                  onClick={() => ""}
                  type="submit"
                  variant="contained"
                  color="primary">
                  Edit Feedback
                </LoadingButton>
              </Stack>
            )}
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
