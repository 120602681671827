import { useQuery } from "@tanstack/react-query"
import { getAttendance } from "../../../services/attendance/apiAttendance";


export const AttendanceQuery = ({year, month})=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['attendance',year,month],
        queryFn: ()=> getAttendance(year, month)
    })
    const attendanceList = data?.data.filter((ele)=>ele.status !== 'Weekend').map((ele)=>{
        return {
            id: ele.date, 
            start: ele.date, 
            title: ele.status==='Absent'?'Leave':ele.status}
});
    return {attendanceList, isLoading, isError};
}