import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import viewIcon from "../../../styles/icons/viewicon.svg";
import { NoticesListQuery } from "../../../utils/reactQuery/notice/NoticeList";
import NotesPopup from "../../../components/NotesPopup/NotesPopup";
import { useResponsive } from "../../../hooks/customUseResponsive";
import { useMutation } from "@tanstack/react-query";
import { usDateFormate } from "../../../hooks/dateFormat";
import { postReadNotice } from "../../../services/notice/apiReadNotice";

export default function Notices() {
  const { noticeList, refetch } = NoticesListQuery();
  const [notePopup, setNotePopup] = useState(false);
  const [noticeId, setNoticeId] = useState("");
  const isMobile = useResponsive("down", "sm");

  const { mutate } = useMutation({
    mutationFn: postReadNotice,
    onSuccess: (data) => {
      refetch();
    },
    onError: () => {
      alert("there was an error");
    },
  });

  const handleView = (id) => {
    setNoticeId(id);
    setNotePopup(true);
    mutate({ id });
  };

  const columns = [
    {
      field: "col1",
      headerName: "Subject",
      minWidth: isMobile ? 150 : 250,
      flex: 2,
      sortable: false,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack direction="row" className={styles.noticeSubject}>
            <Typography className={styles.title}>
              {value.formattedValue.name}
            </Typography>
            {value.formattedValue.isRead ? (
              ""
            ) : (
              <Box className={styles.greenBadge}></Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: "col2",
      headerName: "Create Date",
      minWidth: isMobile ? 100 : 161,
      flex: 1.1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{usDateFormate(value.value)}</span>,
    },
    {
      field: "col3",
      headerName: "Actions",
      minWidth: isMobile ? 100 : 123,
      sortable: false,
      flex: 0.8,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack
            className={styles.viewCont}
            onClick={() => handleView(value.value)}>
            <img className={styles.icon} src={viewIcon} alt="view"/>
          </Stack>
        );
      },
    },
  ];
  const initialRows = noticeList
    ? noticeList.map((ele, index) => {
        return {
          id: index,
          col1: { name: ele.policy_name, isRead: ele.is_read_by_employee },
          col2: ele.date_added,
          col3: ele.policy_id,
        };
      })
    : [];

  return (
    <Box className={styles.mainContainer}>
      <TableHeader heading={"Notices"} />
      <Box className={styles.datagridCont}>
        <DataGridComp columns={columns} initialRows={initialRows} />
      </Box>
      {notePopup ? (
        <NotesPopup
          openPop={notePopup}
          handleClose={() => setNotePopup(false)}
          id={noticeId}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
