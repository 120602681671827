import { useQuery } from "@tanstack/react-query";
import { getLeaveRequestsList } from "../../../../services/manager/apiLeaveRequest";
import { usDateFormate } from "../../../../hooks/dateFormat";

export default function LeaveRequestQuery(){
    const {data, isLoading, refetch} = useQuery({
        queryKey: ['leaveRequest'],
        queryFn: ()=>getLeaveRequestsList()
    })

    const leaveRequestList = data?.data.leave_logs.map((ele,idx)=>{
        return {
            id: idx,
            col1: ele.employee_name,
            col2: usDateFormate(ele.start_date),
            col3: usDateFormate(ele.end_date),
            col4: ele.total_days,
            col5: 'Casual',
            col6: usDateFormate(ele.applied_on),
            col7: ele.status,
            col8: {id: ele.application_id, status: ele.status, isPast: new Date()>=new Date(ele.start_date)}
        }
    })

    return {leaveRequestList, isLoading, refetch}
}