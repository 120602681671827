import * as Yup from 'yup';

const AddAttendanceSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee ID is required'),
  startDate: Yup.date().required('Start Date is required'),
  reason: Yup.string().required('Reason is required'),
  type: Yup.string().required('Type is required'),
  inTime: Yup.string().when('type', {
    is: (value) => value === 'in' || value === 'in-out',
    then: (schema) => schema.required('In Time is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  outTime:Yup.string().when('type', {
    is: (value) => value === 'out' || value === 'in-out',
    then: (schema) => schema.required('Out Time is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  // inTime: Yup.string().when('type', {
  //   is: 'in-out',
  //   then: Yup.string().required('In Time is required'),
  //   otherwise: Yup.string().nullable(),
  // }),
  // outTime: Yup.string().when('type', {
  //   is: 'in-out',
  //   then: Yup.string().required('Out Time is required'),
  //   otherwise: Yup.string().nullable(),
  // }),
});

export default AddAttendanceSchema;
