import * as yup from "yup";

export const addAdminLeaveSchema = yup.object().shape({
  employeeId: yup.string().required("Please select employee"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup
    .date()
    .required("End date is required")
    .min(yup.ref("startDate"), "End date cannot be before start date"),
  message: yup.string().required("message is required field"),
  startTime: yup.string().required("required"),
  endTime: yup.string().required("required"),
  applicationType: yup.string().required("required"),
});
