import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useResponsive } from "../../hooks/customUseResponsive";

const RHFTimePicker = ({ name, label }) => {
  const { control } = useFormContext();
  const isMobile = useResponsive('down','sm');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          label={label}
          value={field.value}
          onChange={(time) => {
            field.onChange(time);
          }}
          sx={{
            '& .MuiInputBase-root':{
              height: isMobile ? '30px !important' :'40px !important',
              backgroundColor: '#fff',
              fontSize: isMobile ? '12px' : ''
            }
          }}
          slotProps={{
            textField: {
              fullWidth: isMobile ? false :true,
              variant: 'outlined',
              error: !!error,
              helperText: error?.message,
              style: { height: isMobile ? '30px !important' :'40px !important',
                fontSize: isMobile ? '12px' : ''
               },
            },
          }}
          {...field}
        />
      )}
    />
  );
};

export default RHFTimePicker;
