import React from 'react'
import styles from './styles.module.css'
import { Box, Button, Stack, Typography } from '@mui/material'

export default function TableHeader({heading, btntxt, handleModal}) {
  return (
    <Box className={styles.container}>
        <Box className={styles.greenTab}/>
      <Stack direction='row' className={styles.headerContainer}>
        <Typography className={styles.tableHead}>
          {heading}
        </Typography>
        {btntxt ? <Button className={styles.btnClass}  onClick={()=> handleModal()} variant="contained" color="primary">
          {btntxt}
        </Button> : ''}
      </Stack>
    </Box>
  )
}
