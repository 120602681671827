import { useQuery } from "@tanstack/react-query"
import { getEmployeeUnderManager } from "../../../services/manager/apiEmloyeeUnderManager"

export const EmployeeUnderManagerQuery=()=>{
    const {data, isLoading}= useQuery({
        queryKey: ['employeeUnderManager'],
        queryFn: ()=>getEmployeeUnderManager()
    })

    const employeeList = data?.data

    return {employeeList, isLoading}
}