import React, { useState } from "react";
import styles from "./styles.module.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import shortHoursIcon from "../../../styles/icons/lateComing.svg";
import expandIcon from "../../../styles/icons/expandIcon.svg";
import MissingStatusModal from "../MissingStatusModal";
import ShortHoursList from "../ShortHours";
import OneWayEntries from "../OneWayEntries";
import { ShortDaysCount } from "../../../utils/reactQuery/manager/count/ShortDaysCount";
import { MissingEntryCount } from "../../../utils/reactQuery/manager/count/MissingEntryCount";
import { MissingStatusCount } from "../../../utils/reactQuery/manager/count/MissingStatusCount";
export default function CardSection() {
  const [missingStatusModal, setMissingStatus] = useState(false);
  const [shortHoursModal, setShortHoursModal] = useState(false);
  const [oneWayEntriesModal, setOneWayEntriesModal] = useState(false);
  const { shortDayCount, shortHourLoading} = ShortDaysCount();
  const { missingEntryCount, missingEntryLoading} = MissingEntryCount();
  const { missingStatusCount, missingStatusLoading} = MissingStatusCount();

  console.log("missing status count", missingStatusCount);

  return (
    <Stack className={styles.cardCont}>
      <span id="teamDashboard" className={styles.teamDashboardIcon}></span>
      <Box className={styles.cardItem} onClick={() => setMissingStatus(true)}>
        <Box className={styles.greenTab} />
        <Stack className={styles.expandCont}>
          <Stack className={styles.expandCard}>
            <img className={styles.expandIcon} src={expandIcon} alt="expand"/>
            <Typography className={styles.expandContent}>
              Expand in detail
            </Typography>
          </Stack>
        </Stack>

        <Stack className={styles.cardMainSection}>
          <img className={styles.mainCardIcon} src={shortHoursIcon} alt="short hours"/>
          <Typography className={styles.mainContent}>
            Employees Missing Status
          </Typography>
        </Stack>

        <Stack className={styles.cardFooter}>
          <Stack className={styles.employeeCont}>
            {missingStatusLoading? <CircularProgress/>:''}
            <Typography className={styles.employeeCount}>
              {missingStatusCount < 10
                ? `0${missingStatusCount}`
                : missingStatusCount}
            </Typography>
            <Typography className={styles.employeeText}>Employees</Typography>
          </Stack>
          <Typography className={styles.lastWeekText}>In last month</Typography>
        </Stack>
      </Box>

      <Box className={styles.cardItem} onClick={() => setShortHoursModal(true)}>
        <Box className={styles.greenTab} />
        <Stack className={styles.expandCont}>
          <Stack className={styles.expandCard}>
            <img className={styles.expandIcon} src={expandIcon} alt="expand"/>
            <Typography className={styles.expandContent}>
              Expand in detail
            </Typography>
          </Stack>
        </Stack>

        <Stack className={styles.cardMainSection}>
          <img className={styles.mainCardIcon} src={shortHoursIcon} alt="short hours"/>
          <Typography className={styles.mainContent}>
            Employees Short Hours
          </Typography>
        </Stack>

        <Stack className={styles.cardFooter}>
          <Stack className={styles.employeeCont}>
          {shortHourLoading? <CircularProgress/>:''}
            <Typography className={styles.employeeCount}>
              {shortDayCount < 10 ? `0${shortDayCount}` : shortDayCount}
            </Typography>
            <Typography className={styles.employeeText}>Employees</Typography>
          </Stack>
          <Typography className={styles.lastWeekText}>In last month</Typography>
        </Stack>
      </Box>

      <Box
        className={[styles.cardItem, styles.lastCardItem]}
        onClick={() => setOneWayEntriesModal(true)}>
        <Box className={styles.greenTab} />
        <Stack className={styles.expandCont}>
          <Stack className={styles.expandCard}>
            <img className={styles.expandIcon} src={expandIcon} alt="expand"/>
            <Typography className={styles.expandContent}>
              Expand in detail
            </Typography>
          </Stack>
        </Stack>

        <Stack className={styles.cardMainSection}>
          <img className={styles.mainCardIcon} src={shortHoursIcon} alt="ahort hours"/>
          <Typography className={styles.mainContent}>
            Employees
            <br /> One Way Entries
          </Typography>
        </Stack>

        <Stack className={styles.cardFooter}>
          <Stack className={styles.employeeCont}>
          {missingEntryLoading? <CircularProgress/>:''}
            <Typography className={styles.employeeCount}>
              {missingEntryCount < 10
                ? `0${missingEntryCount}`
                : missingEntryCount}
            </Typography>
            <Typography className={styles.employeeText}>Employees</Typography>
          </Stack>
          <Typography className={styles.lastWeekText}>In last month</Typography>
        </Stack>
      </Box>

      <MissingStatusModal
        openProp={missingStatusModal}
        handleClose={() => setMissingStatus(false)}
      />

      <ShortHoursList
        openProp={shortHoursModal}
        handleClose={() => setShortHoursModal(false)}
      />

      <OneWayEntries
        openProp={oneWayEntriesModal}
        handleClose={() => setOneWayEntriesModal(false)}
      />
    </Stack>
  );
}
