import { useQuery } from "@tanstack/react-query"
import { getLeaveStatement } from "../../../services/leave/apiLeaveStatement"
import toast from "react-hot-toast";

const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LeaveStatementQuery = (year)=>{
    const {data, isLoading, error} = useQuery({
        queryKey: ['leaveStatement',{year}],
        queryFn: ()=>getLeaveStatement({year})
    })

    if(error){
        toast.error(error.response.data.error)
    }

    let leaveStatement = data?.data.map((ele,idx)=>{
        return {
            id: idx,
        col1: `${monthNames[ele.month]} ${ele.year}`,
        col2: ele.previous_cl_balance,
        col3: ele.cl_allotted,
        col4: ele.cl_used,
        col5: ele.late_coming,
        col6: ele.early_going,
        col7: ele.lwp,
        col8: ele.encashed,
        col9: ele.compoff_adjusted,
        col10: ele.cl_closing_balance
    }

    })
    return {leaveStatement, isLoading}
}