import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { EmployeeFeedbackListQuery } from "../../../utils/reactQuery/manager/EmployeeFeedback/EmployeeFeedbackList";
import viewIcon from "../../../styles/icons/viewicon.svg";
import editIcon from "../../../styles/icons/editIcon.svg";
import AddFeedbackModal from "./AddFeedbackModal";
import ViewFeedback from "./ViewFeedback";
import EditFeedback from "./EditFeedback";

export default function EmployeePerformanceFeedback() {
  const { feedbackList, refetch } = EmployeeFeedbackListQuery();
  const [addFeedbackModal, setAddFeedbackModal] = useState(false);
  const [viewFeedbackModal, setViewFeedbackModal] = useState(false);
  const [editFeedbackModal, setEditFeedbackModal] = useState(false);
  const [selectedId, seSelectedId] = useState("");

  const handleOpenViewFeedback = (id) => {
    setViewFeedbackModal(true);
    seSelectedId(id);
  };

  const handleOpenEditFeedback = (id) => {
    setEditFeedbackModal(true);
    seSelectedId(id);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1.7,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Role",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Quarter",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Action",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleOpenViewFeedback(value?.value)}
            alt="view icon"
          />
          <img
            className={styles.icon}
            src={editIcon}
            onClick={() => handleOpenEditFeedback(value?.value)}
            alt="edit icon"
          />
        </Stack>
      ),
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      <span
        id="employeesPerformanceFeedback"
        className={styles.employeesPerformanceFeedback}></span>

      <Stack className={styles.headerContainer}>
        <Typography className={styles.heading}>
          Employees Performance Feedback
        </Typography>

        <Button
          className={styles.btnClass}
          onClick={() => setAddFeedbackModal(true)}
          variant="contained"
          color="primary">
          Add Feedback
        </Button>
      </Stack>

      <Box>
        <DataGridComp
          columns={columns}
          initialRows={feedbackList ? feedbackList : []}
        />
      </Box>

      <AddFeedbackModal
        openProp={addFeedbackModal}
        handleClose={() => setAddFeedbackModal(false)}
        refetch={refetch}
      />

      {viewFeedbackModal ? (
        <ViewFeedback
          openProp={viewFeedbackModal}
          handleClose={() => setViewFeedbackModal(false)}
          id={selectedId}
        />
      ) : (
        ""
      )}

      {editFeedbackModal ? (
        <EditFeedback
          openProp={editFeedbackModal}
          handleClose={() => setEditFeedbackModal(false)}
          id={selectedId}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
