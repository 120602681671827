import { useQuery } from "@tanstack/react-query"
import { getEmployeeFeedbackList } from "../../../../services/manager/EmployeeFeedback/apiEmplyeeFeedbackList"

export const EmployeeFeedbackListQuery=()=>{
    const {data, isLoading, refetch} = useQuery({
        queryKey: ['employeeFeedbackList'],
        queryFn: ()=>getEmployeeFeedbackList()
    })

    const feedbackList = data?.data.feedback_data.map((ele,idx)=>{
        return{
            id: idx,
            col1: ele.employee_name,
            col2: ele.department,
            col3: ele.quarter,
            col4: ele.feedback_id
        }
    })

    return {feedbackList, refetch}
}