import { useQuery } from "@tanstack/react-query"
import { getViewPolicy } from "../../../services/policy/apiViewPolicy";

export const ViewPolicy = ({id})=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['viewPolicy',id],
        queryFn: ()=> getViewPolicy(id)
    })
    const note = data?.data[0];
    // const note = data?.data[0].description;
    
    return {note, isLoading, isError};
}