import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import LeaveRequestQuery from "../../../utils/reactQuery/manager/leaveRequest/LeaveRequestQuery";
import rejectIcon from "../../../styles/icons/crossIcon.svg";
import approvedIcon from "../../../styles/icons/approvedIcon.svg";
import viewIcon from "../../../styles/icons/viewicon.svg";
import ManagerNote from "../../../components/managerNote/ManagerNote";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postApproveLeaveRequest } from "../../../services/manager/leaveRequest/apiApprovedLeaveRequest";
import RejectRequestModal from "../../../components/RejectRequestModal";
import { postRejectLEaveRequest } from "../../../services/manager/leaveRequest/apiRejectleaveRequest";
import AddLeaveModal from "../../Dashboard/LeaveManagement/AddLeaveModal/AddLeaveModal";
export default function LeaveRequests() {
  const [notePopover, setNotePopover] = useState(false);
  const { leaveRequestList, refetch, isLoading } = LeaveRequestQuery();
  const [selectedId, setSelectedId] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectRequestModal, setRejectRequestModal] = useState(false);
  const [modal, setModal] = useState({ addLeave: false });
  const queryClient = useQueryClient();
  const listOfEmp = queryClient.getQueryData(['employeeUnderManager'])?.data;

  const { mutate } = useMutation({
    mutationFn: postApproveLeaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.log("error employee feedback", data);
    },
  });

  const { mutate: mutateRejectRequest } = useMutation({
    mutationFn: postRejectLEaveRequest,
    onSuccess: (data) => {
      refetch();
      toast.success(data.data.message);
      setApproveModal(false);
    },
    onError: (data) => {
      console.log("error employee feedback", data);
    },
  });

  const handleView = (id) => {
    setNotePopover(true);
    setSelectedId(id);
  };

  const handleApproveApplication = (id) => {
    setSelectedId(id);
    setApproveModal(true);
  };

  const confirmApproveApplication = () => {
    mutate(selectedId);
    setApproveModal(false);
  };

  const handleRejectApplication = (id) => {
    setSelectedId(id);
    setRejectRequestModal(true);
  };

  const handleRejectRequest = (reason) => {
    mutateRejectRequest({ id: selectedId, reason });
    setRejectRequestModal(false);
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Start Date",
      minWidth: 127,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "End Date",
      minWidth: 126,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Total Days",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Leave Type",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "Applied On",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col7",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.statusCont}>
          <Typography
            className={
              value.value === "Approved"
                ? styles.statusApproved
                : styles.statusPending
            }>
            {value.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "col8",
      headerName: "Actions",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <Stack className={styles.iconsCont}>
          <img
            className={styles.icon}
            src={viewIcon}
            onClick={() => handleView(value.value.id)}
            alt="view"
          />
          {!value.value.isPast &&
            (value.value.status === "Pending" ? (
              <>
                <img
                  className={styles.icon}
                  src={approvedIcon}
                  onClick={() => handleApproveApplication(value.value.id)}
                  alt="approve"
                />
                <img
                  className={styles.icon}
                  src={rejectIcon}
                  onClick={() => handleRejectApplication(value.value.id)}
                  alt="reject"
                />
              </>
            ) : value.value.status === "Rejected" ? (
              <img
                className={styles.icon}
                src={approvedIcon}
                onClick={() => handleApproveApplication(value.value.id)}
                alt="approve"
              />
            ) : (
              <img
                className={styles.icon}
                src={rejectIcon}
                onClick={() => handleRejectApplication(value.value.id)}
                alt="reject"
              />
            ))}
        </Stack>
      ),
    },
  ];
  return (
    <Box className={styles.mainContainer}>
      <span id="leaveRequest" className={styles.leaveRequest}></span>
      <Stack className={styles.headerContainer}>
        <Typography className={styles.heading}>Leave Request</Typography>

        <Button
          className={styles.btnClass}
          onClick={() => setModal({ ...modal, addLeave: true })}
          variant="contained"
          color="primary">
          Add Employee Leave
        </Button>
      </Stack>
      <Box className={styles.datagridCont}>
        {isLoading ? (
          <Stack className={styles.loaderCont}>
            <CircularProgress />
          </Stack>
        ) : (
          ""
        )}
        <DataGridComp
          columns={columns}
          initialRows={leaveRequestList ? leaveRequestList : []}
        />
      </Box>
      {notePopover ? (
        <ManagerNote
          openPop={notePopover}
          handleClose={() => setNotePopover(false)}
          id={selectedId}
        />
      ) : (
        ""
      )}
      {approveModal ? (
        <ConfirmModal
          openProp={approveModal}
          handleClose={() => setApproveModal(false)}
          handleConfirm={() => confirmApproveApplication()}
          tabHeader="Approve Request"
          content="Do you want to proceed with approving this request?"
          btnText="approve"
        />
      ) : (
        ""
      )}
      <RejectRequestModal
        openProp={rejectRequestModal}
        handleClose={() => setRejectRequestModal(false)}
        handleConfirm={handleRejectRequest}
      />
      {modal.addLeave && (
        <AddLeaveModal
          openProp={modal.addLeave}
          handleClose={() => setModal({ ...modal, addLeave: false })}
          listOfEmp={listOfEmp}
        />
      )}
    </Box>
  );
}
